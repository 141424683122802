import React, { FunctionComponent } from 'react';
import { OrderBy, useSelectCurrentBilikZoneQuery } from 'generated/graphql';
import { SelectCurrentBilikZoneContainer } from './select-current-bilik-zone-container';

type SelectCurrentBilikZoneRepositoryProps = {
  onChange?: (zoneId: number) => void;
  value?: number;
  allAuthorizedZones?: boolean;
  disabled?: boolean;
};

const SelectCurrentBilikZoneRepository: FunctionComponent<
  SelectCurrentBilikZoneRepositoryProps
> = ({ onChange, value, allAuthorizedZones, disabled }) => {
  const { loading, data } = useSelectCurrentBilikZoneQuery({
    variables: {
      orderBy: [{ name: OrderBy.Asc }],
    },
  });

  return (
    <SelectCurrentBilikZoneContainer
      loading={loading}
      bilikZones={data?.bilikZone ?? []}
      onChange={onChange}
      value={value}
      allAuthorizedZones={allAuthorizedZones}
      disabled={disabled}
    />
  );
};

export default SelectCurrentBilikZoneRepository;
