import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';
import { useSelectBilikPersonFieldQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

type SelectBilikPersonFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled'
> & {
  filters?: {
    proViewId?: number;
    status?: 'published' | 'archived';
    exceptIds?: number[];
  };
};

const SelectBilikPersonField: FunctionComponent<
  SelectBilikPersonFieldProps
> = ({ multiple, filters, ...rest }) => {
  const { data, loading, error } = useSelectBilikPersonFieldQuery({
    variables: {
      where: {
        ...(filters?.proViewId
          ? {
              managedBilikZones: {
                bilikZone: { proViews: { id: { _eq: filters.proViewId } } },
              },
            }
          : {}),
        ...(filters?.status ? { status: { _eq: filters.status } } : {}),
      },
    },
  });

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.bilikPerson) {
      const bilikPeople =
        filters?.exceptIds?.length ?? 0 > 0
          ? data.bilikPerson.filter(
              (bilikPerson) => !filters?.exceptIds?.includes(bilikPerson.id),
            )
          : data.bilikPerson;

      return bilikPeople.map((bilikPerson) => ({
        value: bilikPerson.id,
        key: bilikPerson.id,
        text: `${bilikPerson.familyName} ${bilikPerson.givenName}`,
      }));
    }
    return [];
  }, [data?.bilikPerson, filters?.exceptIds]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des utilisateurs...';
    }
    if (multiple) {
      return 'Selectionnez des utilisateurs...';
    }
    return 'Selectionnez un utilisateur...';
  }, [loading, multiple]);

  if (error) return <>Error! {error.message}</>;

  return (
    <FormField
      type="select"
      disabled={loading}
      loading={loading}
      options={options}
      placeholder={placeholder}
      multiple={multiple}
      {...rest}
    />
  );
};

export default SelectBilikPersonField;
