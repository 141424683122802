import { useSelectBilikZoneQuery } from 'generated/graphql';
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

interface SelectBilikZoneProps {
  onChange?: (zoneId: number) => void;
  value?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const SelectBilikZone = ({
  onChange,
  value,
  style,
  disabled,
}: SelectBilikZoneProps) => {
  const { data, loading } = useSelectBilikZoneQuery();

  const [selectedBilikZone, setSelectedBilikZone] = useState<
    number | undefined
  >(value);

  useEffect(() => {
    setSelectedBilikZone(value);
  }, [value]);

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.bilikZone) {
      const bilikZones = data.bilikZone;

      return bilikZones.map((bilikZone) => ({
        key: bilikZone.id,
        value: bilikZone.id,
        text: bilikZone.mainCityCode,
      }));
    }
    return [];
  }, [data?.bilikZone]);

  return (
    <Dropdown
      style={style}
      selection
      loading={loading}
      disabled={loading || disabled}
      onChange={(_, { value }): void => {
        if (onChange) {
          onChange(Number(value));
        }
        setSelectedBilikZone(Number(value));
      }}
      value={selectedBilikZone}
      options={options || []}
      placeholder={'Selectionnez une zone...'}
    />
  );
};
